<template>
    <div class="char-history-page">
         <el-card class="basic-card history-list-card">
            <div slot="header" class="flex flex-align-center">
                <div class="padding-t-10 padding-d-15">流量监测</div>
                <div class="flex-1 flex flex-justify-end">
                   <!--  <div>
                        <el-date-picker style="width: 228px; border-radius: 50px;"
                            v-model="dates"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="small" round>
                        </el-date-picker>
                    </div> -->
                </div>
            </div>
            <el-table :data="historyList" style="width: 100%;">
              <!-- <el-table-column type="selection" width="45"></el-table-column> -->
              <el-table-column label="成员" prop="name"></el-table-column>
              <el-table-column label="角色" prop="role" width="170"></el-table-column>
              <el-table-column label="模块" prop="modal"></el-table-column>
              <el-table-column label="字符数" prop="chars" width="160"></el-table-column>
              <el-table-column label="日期" width="210" prop="time"></el-table-column>
            </el-table>
            <div class="pagination-content margin-t-15" style="margin-bottom: 0;">
                <el-pagination background :total="total" :current-page="pageNumber" :page-size="pageSize" @current-change="handleOrderCurrentChange" layout="total, prev, pager, next"></el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            historyList: [],
            total: 0,
            pageNumber: 1,
            pageSize: 10,
            dates: [],
        }
    },
    methods: {
        initPage(){

        },

        handleOrderCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        }
    },
    mounted() {

    },
}
</script>
<style lang="scss" scoped>
.char-history-page{
    .basic-card{
        box-shadow: initial;
    }
    .history-list-card{
        ::v-deep .el-card__header{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}
</style>
